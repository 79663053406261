import { BrowserModule } from "@angular/platform-browser";
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { appRoutes } from "./app.routing";
import { AppComponent } from "./app.component";
import { LegalComponent } from "./legal/legal.component";
import { RouterModule, Routes } from "@angular/router";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { HeaderComponent } from "./header/header.component";
import { CompanyComponent } from "./company/company.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { IndividualComponent } from "./individual/individual.component";
import { FeeComponent } from "./fee/fee.component";
import { ContactComponent } from "./contact/contact.component";
import { AgmCoreModule } from "@agm/core";
import { MenuItemComponent } from "./menu-item/menu-item.component";
import { Title } from "@angular/platform-browser";
import { MovementService } from "./movement.service";
import {
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from "@angular/platform-browser";
import { AbstractContentComponent } from "./abstract-content/abstract-content.component";
import { CookieConsentComponent } from "./cookie-consent/cookie-consent.component";
import {
  MatIcon,
  MatCheckboxModule,
  MatIconModule,
  MatButtonModule,
} from "@angular/material";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PrivacyComponent } from "./privacy/privacy.component";

export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: "pan-y",
    });
    return mc;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LegalComponent,
    SidebarComponent,
    HeaderComponent,
    CompanyComponent,
    PrivacyComponent,
    IndividualComponent,
    FeeComponent,
    ContactComponent,
    MenuItemComponent,
    AbstractContentComponent,
    CookieConsentComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule.forRoot(appRoutes),
    FlexLayoutModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAnOj2skySQaV2tMoBmN5546NCVWFNm2Ac",
    }),
    MatIconModule,
    MatButtonModule,
    BrowserAnimationsModule,
  ],
  // exports: [MatIconModule],
  providers: [
    Title,
    MovementService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [],
})
export class AppModule {}
