import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { routeSlider } from '../common/animation';
import { Router } from '@angular/router';
import { MovementService } from '../movement.service';
import { AbstractContentComponent } from '../abstract-content/abstract-content.component';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: [
    './privacy.component.scss', '../common/common.scss'
  ],
  animations: [routeSlider],
})
export class PrivacyComponent extends AbstractContentComponent implements OnInit {
  private direction: String;
  @HostBinding('@routeSlider') get routeSlider() {
    return this.direction;
  }

  constructor(movementService: MovementService, router: Router) {
    super(router, movementService);
    this.direction = movementService.getMovementDirection();
  }

  ngOnInit() {
    // console.log('company init');
    // console.log(this.direction);
  }

}
